
import {defineComponent} from "vue";
import ListHeader1 from "@/components/common/ListHeader1.vue"


export default defineComponent({
  name: "kt-account",
  components: {
    ListHeader1,
  },
  data() {
    return {
      headerShow: false,
      userID: null,
    }
  },
  methods: {},
  setup() {
    return {
      open,
    }
  },
});
